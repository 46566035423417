import { AlertBanner, Button } from '@missionlabs/smartagent-app-components'
import classnames from 'classnames'
import { CustomerJourney } from 'components/CustomerJourney'
import QueueTime from 'components/QueueTime'
import useContact from 'hooks/redux/useContact'
import useHasFeature, { AppFeatures } from 'hooks/useHasFeature'
import leftArrowGreen from 'images/icon-arrow-left-green.svg'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectApp } from 'store/app/app.selectors'
import { WidgetType } from 'store/app/app.state'
import { clearContact } from 'store/contact/contact.actions'
import RootState from 'store/state'
import Plugins from '../Plugins'
import { Widgets } from '../Widgets'
import './switcher.scss'

export interface SwitcherProps {
    type: 'call' | 'default' | 'contact-log'
    widgetsType?: WidgetType
    defaultView: string | undefined
    journey: string
    showQueueTime: boolean
}

interface ViewType {
    type?: 'plugin' | 'widget'
    view?: string
}

export const PluginSwitcher: React.FC<SwitcherProps> = ({
    type,
    widgetsType,
    defaultView,
    journey,
    showQueueTime,
}) => {
    const pluginViews = useSelector<RootState, ViewType[]>((state) => {
        if (!state.app.plugins.length) return []
        const views = Array.from(
            new Set(
                state.app.plugins
                    .filter((p) => p.type === type || p.type === 'always')
                    .map((p) => p.view),
            ),
        ).map<ViewType>((view) => ({
            view,
            type: 'plugin',
        }))

        if (views.length === 1) return [{ view: views[0].view, type: 'plugin' }]
        return views
    })
    const hasFeature = useHasFeature()
    const hasWidgetType = {
        contactHistory: hasFeature(AppFeatures.CONTACT_HISTORY),
        callContact:
            hasFeature(AppFeatures.CALL_DETAILS) ||
            hasFeature(AppFeatures.CALL_RECORDING) ||
            hasFeature(AppFeatures.CALL_TRANSCRIPTION) ||
            hasFeature(AppFeatures.CONTACT_HISTORY) ||
            hasFeature(AppFeatures.VIDEO_MEETING),
        chatContact:
            hasFeature(AppFeatures.CHAT_DETAILS) ||
            hasFeature(AppFeatures.CHAT_TRANSCRIPTION) ||
            hasFeature(AppFeatures.CONTACT_HISTORY),
        realTime: hasFeature(AppFeatures.CALL_PLUCKING),
        taskContact:
            hasFeature(AppFeatures.TASK_DETAILS) || hasFeature(AppFeatures.CONTACT_HISTORY),
    }

    const views: ViewType[] = useMemo(() => {
        const addWidgets = (otherViews: ViewType[]): ViewType[] => {
            const contactHistoryWidget: ViewType[] = hasFeature(AppFeatures.CONTACT_HISTORY)
                ? [{ view: 'contactHistory', type: 'widget' }]
                : []

            const videoMeetingWidget: ViewType[] =
                hasFeature(AppFeatures.VIDEO_MEETING) && widgetsType == 'callContact'
                    ? [{ view: 'videoMeeting', type: 'widget' }]
                    : []

            return [
                {
                    view: widgetsType,
                    type: 'widget',
                },
                ...otherViews,
                ...contactHistoryWidget,
                ...videoMeetingWidget,
            ]
        }

        if (widgetsType && hasWidgetType[widgetsType]) {
            return addWidgets(pluginViews)
        }

        return pluginViews
    }, [pluginViews, widgetsType])

    const initialView = useMemo(() => {
        if (defaultView) {
            return views.find((v) => v.view === defaultView)
        }

        return views[0]
    }, [])

    const [selected, setSelected] = useState<ViewType | undefined>(initialView)
    const [hideViews, setHideViews] = useState<string>('')

    const dispatch = useDispatch()
    const contact = useContact()

    const inCall = useSelector<RootState, boolean>((state) => !!state.call && !!state.call.inCall)
    const inChat = useSelector<RootState, boolean>((state) => state.chat.connections.length > 0)
    const inTask = useSelector<RootState, boolean>((state) => state.tasks.connections.length > 0)

    const afterCallWork = useSelector<RootState, boolean>(
        (state) => state.user?.status.name === 'AfterCallWork',
    )

    const isOnContact = inCall || inChat || inTask

    const getSwitcherTabName = useCallback(
        (v: ViewType) => {
            if (v.type === 'plugin') {
                return v.view ?? 'Plugins'
            }

            if (widgetsType === 'realTime') {
                return 'Real-time Data'
            }

            if (v.view === 'contactHistory') {
                return 'Contact History'
            }

            if (v.view === 'videoMeeting' && (isOnContact || afterCallWork)) {
                return 'Video Sharing'
            }

            return 'Contact'
        },
        [widgetsType, isOnContact],
    )

    useEffect(() => {
        if (contact?.attributes?.['sa-hide-view']) {
            setHideViews(contact.attributes['sa-hide-view'])
        }
    }, [])

    const widgetAlert = useSelector(selectApp).widgetAlert

    return (
        <>
            {widgetAlert && <AlertBanner>{widgetAlert}</AlertBanner>}
            {views.length ? (
                <div className="sm-mar-bottom row">
                    {!inCall && !afterCallWork && (
                        <Button
                            className="back-button sm-mar-right"
                            onClick={() => dispatch(clearContact())}
                            icon={
                                <img
                                    className="sm-mar-right"
                                    height={10}
                                    src={leftArrowGreen}
                                    alt="Clear"
                                    title="Clear"
                                />
                            }
                        >
                            Clear contact
                        </Button>
                    )}
                    <div className="sa-plugin-switcher">
                        {views.map((v, i) => {
                            {
                                if (hideViews && hideViews.includes(v.view || '')) return null
                            }

                            if (!afterCallWork && getSwitcherTabName(v) === 'Contact') return null

                            return (
                                <Button
                                    onClick={() => setSelected(v)}
                                    className={classnames({
                                        'sa-plugin-switcher-button': true,
                                        selected: v.view === selected?.view,
                                    })}
                                    key={`view-${i}`}
                                >
                                    {getSwitcherTabName(v)}
                                </Button>
                            )
                        })}
                    </div>
                    {inCall && (
                        <div className="customer-journey">
                            {journey && <CustomerJourney breadcrumbs={journey} />}
                            {showQueueTime && <QueueTime />}
                        </div>
                    )}
                </div>
            ) : null}
            <Plugins
                type={type}
                showNone={selected?.type !== 'plugin'}
                selectedView={selected?.view}
            />
            {widgetsType && (
                <Widgets
                    type={widgetsType}
                    showNone={selected?.type !== 'widget'}
                    selectedView={selected?.view}
                    isOnContact={isOnContact}
                />
            )}
        </>
    )
}
