import { Box } from '@missionlabs/smartagent-app-components'
import { H } from 'react-accessible-headings'
import { useDispatch, useSelector } from 'react-redux'
import {
    forgetMeeting,
    getMeetingView,
    selectMeeting,
} from 'store/videoMeeting/videoMeeting.reducer'
import { CreateSession } from './CreateSession'

import { useEffect } from 'react'
import { selectContact } from 'store/contact/contact.selectors'
import { selectUserStatus } from 'store/user/user.selectors'
import { MeetingViews } from 'store/videoMeeting/videoMeeting.state'
import { SendInvite } from './SendInvite'
import { VideoSession } from './VideoSession'
import { VideoSharingHistory } from './VideoSharingHistory'
import styles from './index.module.scss'

export const VideoSharingRoot = () => {
    const dispatch = useDispatch()
    const meetingView = useSelector(getMeetingView)
    const contact = useSelector(selectContact)
    const meeting = useSelector(selectMeeting)
    const userStatus = useSelector(selectUserStatus)?.name

    const elementToRender = () => {
        if (userStatus === 'AfterCallWork') return <VideoSharingHistory contact={contact!} />
        switch (meetingView) {
            case MeetingViews.INVITE:
                return <SendInvite />
            case MeetingViews.VIDEO:
                return <VideoSession />
            default:
                return <CreateSession />
        }
    }

    useEffect(() => {
        if (meeting.contactID !== contact?.ID) dispatch(forgetMeeting())
    }, [])

    return (
        <Box alt collapse boxLabel="Video Sharing" header={<H>Video Sharing</H>}>
            <div className={styles.videoMeetingContainer}>{elementToRender()}</div>
        </Box>
    )
}
