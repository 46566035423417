import { ContactInfo, ContactItem, UnreadMessagesIndicator } from 'components'
import { ReactComponent as ChatFilledIcon } from 'images/chat-icon-filled.svg'
import { ReactComponent as ChatIcon } from 'images/chat-icon.svg'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setSelectedChat } from 'store/chat/chat.actions'
import { IChat } from 'store/chat/chat.state'
import { SocialMediaPlatform } from 'store/chat/chat.utils'
import { channelDisplayMap, getFormattedChannelName } from 'utils'

import styles from './ChatListItem.module.scss'

interface Props {
    chat: IChat
    selected: boolean
    channel?: SocialMediaPlatform
}

const ChatsListItem: React.FC<Props> = ({ chat, selected, channel }) => {
    const dispatch = useDispatch()

    const getLastMessage = () =>
        chat.messages.length ? new Date(chat.messages.slice(-1)[0].AbsoluteTime) : new Date()

    //Cannot click on chat if email and not connected
    const isIncomingEmail = chat.channel === 'EMAIL'

    const onSelectChat = () => {
        dispatch(setSelectedChat(chat.id))
    }

    const getUnreadMessagesAmount = () => {
        if (!!chat.unread && chat.channel !== 'EMAIL') {
            return chat.unread
        }

        return 0
    }

    const renderUnreadMessages = (view: string) => {
        return (
            <>
                {getUnreadMessagesAmount() > 0 && (
                    <div
                        className={styles[`messages--${view}`]}
                        aria-label={`New message from chat`}
                    >
                        <UnreadMessagesIndicator unreadMessages={getUnreadMessagesAmount()} />
                    </div>
                )}
            </>
        )
    }

    return (
        <ContactItem
            onSelect={onSelectChat}
            acw={chat.acw}
            selected={selected}
            disabled={isIncomingEmail && chat.status === connect.ContactStateType.CONNECTING}
            ariaLabel={`Contact card ${channel || 'Chat'} from`}
            contactName={chat.customerName}
            description={getFormattedChannelName({ channel, defaultValue: channelDisplayMap.WEBCHAT })}
            descriptionMobileHidden
            icon={
                selected ? (
                    <ChatFilledIcon
                        title="Chat"
                        data-testid="chat-icon-filled"
                        aria-hidden={true}
                    />
                ) : (
                    <ChatIcon title="Chat" data-testid="chat-icon" aria-hidden={true} />
                )
            }
        >
            <div className={styles.infoWithUnreadMessages}>
                <ContactInfo
                    isSelected={selected}
                    timeFromLastMessage={getLastMessage()}
                    contactTimeStarted={chat.started}
                    acw={chat.acw}
                />

                {renderUnreadMessages('mobile')}
            </div>
            {renderUnreadMessages('desktop')}
        </ContactItem>
    )
}

export default ChatsListItem
