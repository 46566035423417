import { Button } from '@missionlabs/smartagent-app-components'
import useHasFeature, { AppFeatures, AppSubFeatures } from 'hooks/useHasFeature'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteScreenshots } from 'store/videoMeeting/videoMeeting.reducer'
import {
    ScreenshotData,
    Screenshots as ScreenshotState,
} from 'store/videoMeeting/videoMeeting.state'
import { DownloadIcon } from './Icons/Download'
import { ScreenshotsHeading } from './ScreenshotsHeading'
import styles from './index.module.scss'

export interface ScreenshotsProps {
    isHistorical?: boolean
    screenshots?: ScreenshotState
    contactID: string
}

export const Screenshots = ({ screenshots, contactID, isHistorical = false }: ScreenshotsProps) => {
    const dispatch = useDispatch()

    const [fullscreen, setFullscreen] = useState<string | null>(null)
    const [selectedScreenshots, setSelectedScreenshots] = useState<ScreenshotState>({})

    const selectScreenshot = (screenshot: ScreenshotState) => {
        const keys = Object.keys(selectedScreenshots)
        const stateCopy = Object.assign({}, selectedScreenshots)
        if (keys.includes(Object.keys(screenshot)[0])) {
            delete stateCopy[Object.keys(screenshot)[0]]
            setSelectedScreenshots(stateCopy)
        } else {
            setSelectedScreenshots({ ...stateCopy, ...screenshot })
        }
    }

    const handleDelete = (screenshots: ScreenshotState, resetSelected?: boolean) => {
        dispatch(deleteScreenshots({ screenshots, isHistorical }))
        if (resetSelected) setSelectedScreenshots({})
    }

    const handleBulkDownload = () => {
        const toDownload = Object.keys(selectedScreenshots).length
            ? selectedScreenshots
            : screenshots!
        Object.values(toDownload).forEach((screenshot) => {
            handleDownload(screenshot)
        })
    }

    const handleDownload = (screenshot: ScreenshotData) => {
        const link = document.createElement('a')
        link.setAttribute('download', `${contactID}_video-session-screenshot.png`)
        link.target = '_blank'
        link.href = screenshot.src
        link.click()
    }

    const canUseFeature = useHasFeature()
    const canEditVideoSession = canUseFeature(AppFeatures.VIDEO_MEETING, [
        AppSubFeatures.VIDEO_MEETING_EDIT_RECORDS,
    ])

    const hasScreenshots = !!Object.keys(screenshots ?? {}).length
    const hasSelectedScreenshots = !!Object.keys(selectedScreenshots).length

    const getDownloadMessage = () => {
        return `Download ${hasSelectedScreenshots ? 'Selected' : 'All'}`
    }

    if (screenshots) {
        return (
            <>
                {fullscreen && (
                    <div className={styles.fullscreenContainer}>
                        <div className={styles.fullscreen}>
                            <span></span>
                            <img src={fullscreen} alt="screenshot" />
                            <Button
                                styling="white"
                                onClick={() => setFullscreen(null)}
                                className={styles.screenshotButton}
                            >
                                <span
                                    className={['xsm-mar-right', styles.closeIcon].join(' ')}
                                ></span>
                            </Button>
                        </div>
                    </div>
                )}
                {!isHistorical && (
                    <ScreenshotsHeading count={Object.keys(screenshots).length}>
                        {hasSelectedScreenshots && (
                            <Button
                                styling="red"
                                className={styles.button}
                                onClick={() => handleDelete(selectedScreenshots, true)}
                            >
                                Delete Selected
                            </Button>
                        )}
                        {hasScreenshots && (
                            <Button
                                styling="primary"
                                className={styles.button}
                                elastic
                                onClick={() => handleBulkDownload()}
                            >
                                <span className={['xsm-mar-right'].join(' ')}>
                                    <DownloadIcon color="white" />
                                </span>
                                {getDownloadMessage()}
                            </Button>
                        )}
                    </ScreenshotsHeading>
                )}

                <div className={styles.screenshotContainer}>
                    {Object.entries(screenshots).map(([key, screenshot], i) => (
                        <div className={styles.screenshot} key={key}>
                            <div className={styles.ScreenshotContents}>
                                <div className={styles.img}>
                                    <img src={screenshot.src} alt="screenshot" />
                                </div>
                                <div className={styles.screenshotButtons}>
                                    <span>
                                        {!isHistorical && (
                                            <input
                                                type="checkbox"
                                                aria-label="Select Screenshot"
                                                className={styles.screenshotCheck}
                                                onClick={() =>
                                                    selectScreenshot({ [key]: screenshot })
                                                }
                                            />
                                        )}{' '}
                                    </span>
                                    <span>
                                        <Button
                                            styling="white"
                                            aria-label="Download Screenshot"
                                            className={styles.screenshotButton}
                                            onClick={() => handleDownload(screenshot)}
                                        >
                                            <span
                                                className={[
                                                    'xsm-mar-right',
                                                    styles.downloadIcon,
                                                ].join(' ')}
                                            ></span>
                                        </Button>
                                        <Button
                                            styling="white"
                                            aria-label="Fullscreen Screenshot"
                                            onClick={() => setFullscreen(screenshot.src)}
                                            className={styles.screenshotButton}
                                        >
                                            <span
                                                className={[
                                                    'xsm-mar-right',
                                                    styles.fullscreenIcon,
                                                ].join(' ')}
                                            ></span>
                                        </Button>
                                    </span>
                                    <span>
                                        {canEditVideoSession && (
                                            <Button
                                                styling="red"
                                                className={styles.screenshotButton}
                                                aria-label="Delete Screenshot"
                                                onClick={() => handleDelete({ [key]: screenshot })}
                                            >
                                                <span
                                                    className={[
                                                        'xsm-mar-right',
                                                        styles.deleteIcon,
                                                    ].join(' ')}
                                                ></span>
                                            </Button>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }
}
