import { Meeting } from '@aws-sdk/client-connect'
import { Attendee } from 'amazon-chime-sdk-js'

export type VideoMeetingState = {
    loadingStatus: VideoMeetingLoadingStatus
    view: MeetingViews
    meeting: Meeting | null
    agentAttendee: Attendee | null
    contactID: string | null
    smsInviteSentTo: string | null
    isInviteInProgress: boolean
    isDeleteInProgress: boolean
    screenshots?: Screenshots
    status: string
    historicalMeeting?: {
        meetingID?: string
        contactID?: string
        playbackURL?: string
        screenshots?: Screenshots
    }
}

export type Screenshots = Record<PropertyKey, ScreenshotData>

export type ScreenshotData = {
    src: string
}

export enum MeetingViews {
    CREATE = 'create',
    INVITE = 'invite',
    VIDEO = 'video',
}

export enum VideoMeetingLoadingStatus {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export interface CreateMeetingAction {
    contactID: string
}

export interface GetMeetingRecordingAction {
    meetingID: string
    displayNotificationOnFailure: boolean
}

export interface GetScreenshotsAction {
    meetingID: string
    contactID: string
    isHistorical?: boolean
}

export interface ModifyScreenshotsAction {
    screenshots: Screenshots
    isHistorical: boolean
}

export interface SetMeetingInvitedSMSAction {
    brand: string
    smsInviteSentTo: string
    meetingID: string
}

export interface SetMeetingReqLoadingAction {
    isInviteInProgress?: boolean
    isDeleteInProgress?: boolean
}

export interface SetMeetingNavigateAction {
    view: MeetingViews
}

export interface CreateMeetingSuccessAction {
    agentAttendee: Attendee
    meeting: Meeting
}

export interface SetMeetingStatus {
    status: string
}

export interface CreateMeetingFailedAction {
    message?: string
    header?: string
}

export interface SaveScreenshotAction {
    imageData: string
    isHistorical?: boolean
}

export type SaveScreenshotSuccessAction = {
    s3Key: string
    src: string
    isHistorical: boolean
}

export type SaveScreenshotFailedAction = {
    isHistorical: boolean
}
