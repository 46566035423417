import { TimeElapsed, formatDateTime } from '@missionlabs/smartagent-app-components'
import { SATableColumn } from '@missionlabs/smartagent-app-components/dist/Table'
import AttachmentsIcon from 'images/attachment.svg'
import RecordingIcon from 'images/recording-icon.svg'
import { ChannelColumnGroup } from 'store/contactSearch/contactSearch.state'
import { toFriendlyDuration } from 'utils'

interface SATableColumnWithMappings extends SATableColumn {
    channels: ChannelColumnGroup[]
}

export const allColumnMappings: SATableColumnWithMappings[] = [
    {
        name: 'Channel',
        key: 'Channel',
        value: 'channel',
        sort: true,
        channels: [
            ChannelColumnGroup.VOICE,
            ChannelColumnGroup.EMAIL,
            ChannelColumnGroup.TASK,
            ChannelColumnGroup.CHAT,
            ChannelColumnGroup.SOCIAL,
            ChannelColumnGroup.MESSAGING,
            ChannelColumnGroup.WEBFORM,
        ],
    },
    {
        name: 'Attachments',
        key: 'Attachments',
        value: 'attachmentCount',
        sort: true,
        render: (attachmentCount?: number) => {
            if (!attachmentCount) return <></>
            return (
                <div className="contact-search-results-attachments">
                    <img src={AttachmentsIcon} alt="Attachments" />({attachmentCount})
                </div>
            )
        },
        channels: [
            ChannelColumnGroup.EMAIL,
            ChannelColumnGroup.TASK,
            ChannelColumnGroup.CHAT,
            ChannelColumnGroup.SOCIAL,
            ChannelColumnGroup.VOICE,
        ],
    },
    {
        name: 'Recording',
        key: 'Recording',
        value: 'attributes.sa-video-meeting-id',
        sort: true,
        sortField: 'videoMeetingAttachmentCount',
        render: (videoMeetingID?: string) => {
            return videoMeetingID ? (
                <div className="contact-search-results-attachments">
                    <img src={RecordingIcon} alt="Video Recording" />
                </div>
            ) : null
        },
        channels: [ChannelColumnGroup.VOICE],
    },
    {
        name: 'Queue',
        key: 'Queue',
        value: 'queueName',
        sort: true,
        clipFieldValues: true,
        channels: [
            ChannelColumnGroup.VOICE,
            ChannelColumnGroup.EMAIL,
            ChannelColumnGroup.TASK,
            ChannelColumnGroup.CHAT,
            ChannelColumnGroup.SOCIAL,
            ChannelColumnGroup.MESSAGING,
        ],
    },
    {
        name: 'Agent',
        key: 'Agent',
        value: 'agentName',
        sort: true,
        clipFieldValues: true,
        channels: [
            ChannelColumnGroup.VOICE,
            ChannelColumnGroup.EMAIL,
            ChannelColumnGroup.TASK,
            ChannelColumnGroup.CHAT,
            ChannelColumnGroup.SOCIAL,
            ChannelColumnGroup.MESSAGING,
        ],
    },
    {
        name: 'Date',
        key: 'Date',
        value: 'initiationTimestamp',
        render: (timestamp?: number) =>
            timestamp ? formatDateTime(timestamp, { date: true, time: true }) : 'Unknown',
        getAriaDescription: (timestamp?: number) =>
            timestamp ? formatDateTime(timestamp, { date: true, time: true }) : 'Unknown',
        sort: true,
        clipFieldValues: true,
        channels: [
            ChannelColumnGroup.VOICE,
            ChannelColumnGroup.EMAIL,
            ChannelColumnGroup.TASK,
            ChannelColumnGroup.CHAT,
            ChannelColumnGroup.SOCIAL,
            ChannelColumnGroup.MESSAGING,
        ],
    },
    {
        name: 'Initiation',
        key: 'Initiation',
        value: 'initiationMethodDisplay',
        sort: true,
        clipFieldValues: true,
        channels: [
            ChannelColumnGroup.VOICE,
            ChannelColumnGroup.EMAIL,
            ChannelColumnGroup.TASK,
            ChannelColumnGroup.CHAT,
            ChannelColumnGroup.SOCIAL,
            ChannelColumnGroup.MESSAGING,
        ],
    },
    {
        name: 'Duration',
        key: 'Duration',
        value: 'duration',
        render: (value: number) => (
            <TimeElapsed
                hours={value > 3600}
                minutes={value > 60}
                date={TimeElapsed.from(value)}
                annotate
                noTick
                ariaLive="off"
            />
        ),
        getAriaDescription: toFriendlyDuration,
        sort: true,
        channels: [
            ChannelColumnGroup.VOICE,
            ChannelColumnGroup.EMAIL,
            ChannelColumnGroup.TASK,
            ChannelColumnGroup.CHAT,
            ChannelColumnGroup.SOCIAL,
            ChannelColumnGroup.MESSAGING,
        ],
    },
    {
        name: 'External ID',
        key: 'External ID',
        value: 'attributes.sa-externalID',
        sort: true,
        clipFieldValues: true,
        channels: [
            ChannelColumnGroup.VOICE,
            ChannelColumnGroup.EMAIL,
            ChannelColumnGroup.TASK,
            ChannelColumnGroup.CHAT,
            ChannelColumnGroup.SOCIAL,
        ],
    },
    {
        name: 'Telephone Number',
        key: 'Telephone Number',
        value: 'telephoneNumber',
        sort: true,
        clipFieldValues: true,
        channels: [ChannelColumnGroup.VOICE, ChannelColumnGroup.CHAT, ChannelColumnGroup.MESSAGING],
    },
    {
        name: 'Social Handle',
        key: 'Social Handle',
        value: 'socialHandle',
        sort: true,
        clipFieldValues: true,
        channels: [ChannelColumnGroup.SOCIAL],
    },
    {
        name: 'Unique Social ID',
        key: 'Unique Social ID',
        value: 'attributes.sa-customer-endpoint-id',
        sort: true,
        clipFieldValues: true,
        channels: [ChannelColumnGroup.SOCIAL],
    },
    {
        name: 'To/From Email',
        key: 'To/From Email',
        value: 'email',
        sort: true,
        clipFieldValues: true,
        channels: [ChannelColumnGroup.EMAIL, ChannelColumnGroup.TASK, ChannelColumnGroup.CHAT],
    },
    {
        name: 'CC Email',
        key: 'CC Email',
        value: 'ccEmailList',
        sort: true,
        clipFieldValues: true,
        channels: [ChannelColumnGroup.EMAIL, ChannelColumnGroup.TASK],
    },
    {
        name: 'Subject',
        key: 'Subject',
        value: 'subject',
        sort: true,
        clipFieldValues: true,
        channels: [ChannelColumnGroup.EMAIL, ChannelColumnGroup.TASK],
    },
]

type TableColumns = Record<ChannelColumnGroup, SATableColumn[]>

const getTableColumns = (): TableColumns => {
    // dynamically create object with properties from enum
    const tableColumns: TableColumns = {} as TableColumns
    Object.keys(ChannelColumnGroup).forEach(
        (channel) => (tableColumns[channel as ChannelColumnGroup] = []),
    )
    // push the tagged columns to each property
    allColumnMappings.forEach((column: SATableColumnWithMappings) => {
        const { channels: _, ...columnWithoutChannels } = column
        tableColumns[ChannelColumnGroup.ALL].push(columnWithoutChannels)
        column.channels.forEach((channel) => {
            if (tableColumns[channel]) tableColumns[channel].push(columnWithoutChannels)
        })
    })
    return tableColumns
}

export const allTableColumns: TableColumns = getTableColumns()
