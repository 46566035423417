import { Channel } from '@aws-sdk/client-connect'
import { ETRType, IETR } from '@missionlabs/smartagent-lib-shared/build/etr'
import { SocialChatJsonMessageType } from '@missionlabs/smartagent-service-chat/dist/types/socialChatJsonMessage'
import type { AllThreadsAndMessages, ThreadMessage } from '@missionlabs/smartagent-service-thread'
import { IEmailAttachment, IEmailMessage } from 'components/EmailMessage/types'
import { ReactComponent as AlertIcon } from 'images/alert-icon.svg'
import { orderBy } from 'lodash'
import { getThreads } from 'services/api/api.thread'
import ContactState from 'store/contact/contact.state'
import { channelTypes } from 'store/contactHistory/contactHistory.constants'
import { formatPhoneNumber, getContactDurationSeconds } from 'utils'
import {
    ChannelType,
    ContactHistory,
    ContactSearchResult,
    IInteraction,
    InteractionMessage,
} from './types'

export const isChannelType = (channel: string): channel is ChannelType => {
    return channelTypes[channel as ChannelType] !== undefined
}

export const getChannelType = (contact: ContactState | null): ChannelType => {
    let channelType: ChannelType = 'UNKNOWN'

    if (!contact) return channelType

    const channel = contact.channel
    // Voice channels
    if (channel === 'VOICE') {
        const { initiationMethod: subChannel } = contact
        const fullChannel = `${channel} ${subChannel}`

        if (isChannelType(fullChannel)) channelType = fullChannel
    }
    // Social and Webchat channels
    else if (channel === 'CHAT') {
        const {
            'sa-social-media-platform': socialChannel,
            'sa-sub-channel': socialSubChannel,
            'sa-initiation-method': initiationMethod,
        } = contact.attributes
        if (socialChannel && socialSubChannel) {
            const subChannel =
                initiationMethod === 'outbound'
                    ? 'OUTBOUND'
                    : initiationMethod === 'contact_flow_initiated'
                      ? 'CONTACT-FLOW-INITIATED'
                      : socialSubChannel

            const fullChannel = `${socialChannel} ${subChannel}`

            if (isChannelType(fullChannel)) channelType = fullChannel
        } else {
            channelType = 'WEBCHAT'
        }
    }
    // Emails
    else if (channel === 'TASK') {
        channelType = 'TASK'
    }

    return channelType
}

export const getThreadMessageGroups = async (
    contacts: ContactState[],
): Promise<AllThreadsAndMessages[]> => {
    const threadIds = [
        ...new Set(
            contacts.map(
                (contact) =>
                    contact.attributes['sa-parent-thread-id'] || contact.attributes['sa-threadID'],
            ),
        ),
    ]

    const filteredThreadsIds = threadIds.filter((threadId) => threadId) // filter out any undefined values from array

    return filteredThreadsIds.length > 0 ? await getThreads(filteredThreadsIds) : []
}

export const transformTaskContacts = (
    contacts: ContactState[],
    threadMessageGroups: AllThreadsAndMessages[],
): IInteraction[] => {
    // Create interactions to represent every message found inside the thread message groups
    const interactions = threadMessageGroups.reduce(
        (currentInteractions: IInteraction[], threadMessageGroup) => {
            const {
                subject,
                systemEndpointAddress,
                customerEndpointAddress,
                messages,
                hasAutoReply,
            } = threadMessageGroup

            // Transform each message in the group into an email message
            const transformedMessages = messages.map((message) =>
                constructEmailMessage(
                    subject,
                    systemEndpointAddress,
                    customerEndpointAddress,
                    message,
                ),
            )

            // Order messages to read from newest to oldest
            const orderedMessages = orderBy(transformedMessages, 'timestamp', 'desc')

            // Step 1: Matching contacts by contactId in messages
            // Iterate through messages to construct their corresponding interaction and add to the current interactions
            orderedMessages.forEach((message) => {
                // Find the message's corresponding contact to act as the base of the interaction
                const matchingContact = contacts.find((contact) => contact.ID === message.contactId)

                // Only construct the interaction if a matching contact has been found
                if (matchingContact) {
                    const messageInteractionInfo = getInteractionPropertiesFromMessage(
                        message,
                        matchingContact.agentName,
                    )

                    currentInteractions.push({
                        ...matchingContact,
                        ...messageInteractionInfo,
                        emailMessages: orderedMessages,
                        viewPreviousEmailMessages: false,
                        hasAutoReply,
                        attributes: matchingContact.attributes,
                        initiationMethodDisplay: getInitiationMethodToDisplay(matchingContact),
                    } as IInteraction)
                }
            })

            // Step 2: Matching contacts for tasks that are rescheduled
            // Create interactions for any rescheduled contacts without messages
            contacts.forEach((contact) => {
                if (
                    contact.attributes['sa-task-rescheduled'] === 'true' &&
                    contact.attributes['sa-threadID'] ===
                        threadMessageGroup.messages[0]?.threadID &&
                    !currentInteractions.some((interaction) => interaction.ID === contact.ID)
                ) {
                    // Get the properties for the most recent message prior to this contact
                    const thisContactTimestamp =
                        contact.connectedToAgentTimestamp ?? contact.initiationTimestamp
                    let messageInteractionInfo = {}
                    for (const message of orderedMessages) {
                        // Sorted by desc timestamp
                        if (message.timestamp <= thisContactTimestamp) {
                            messageInteractionInfo = getInteractionPropertiesFromMessage(
                                message,
                                contact.agentName,
                            )
                            break
                        }
                    }

                    currentInteractions.push({
                        ...contact,
                        ...messageInteractionInfo,
                        emailMessages: orderedMessages,
                        hasAutoReply,
                        viewPreviousEmailMessages: false,
                        connectedToAgentTimestamp: contact.connectedToAgentTimestamp,
                        channelType: 'TASK INBOUND',
                        attributes: contact.attributes,
                        initiationMethodDisplay: getInitiationMethodToDisplay(contact),
                    } as IInteraction)
                }
            })

            // Step 3: Matching contacts with messages based on threadID
            const threadID = threadMessageGroup.messages[0]?.threadID
            if (threadID) {
                const filteredContactsByThreadID = contacts.filter(
                    (contact) =>
                        contact.attributes['sa-threadID'] === threadID &&
                        !currentInteractions.some((interaction) => interaction.ID === contact.ID),
                )

                // build interactions based on filteredContacts, if any found
                if (filteredContactsByThreadID.length > 0) {
                    const threadIDInteractions = filteredContactsByThreadID.map((contact) => {
                        const messageInteractionInfo = getInteractionPropertiesFromMessage(
                            orderedMessages[0],
                            contact.agentName,
                        )
                        return {
                            ...contact,
                            ...messageInteractionInfo,
                            emailMessages: orderedMessages,
                            hasAutoReply,
                            viewPreviousEmailMessages: false,
                            connectedToAgentTimestamp: contact.connectedToAgentTimestamp,
                            channelType: 'TASK INBOUND',
                            attributes: contact.attributes,
                            initiationMethodDisplay: getInitiationMethodToDisplay(contact),
                        } as IInteraction
                    })

                    currentInteractions.push(...threadIDInteractions)
                }
            }

            return currentInteractions
        },
        [],
    )

    const tasksEnabledForSearch = contacts.filter(
        (contact) => contact.attributes['sa-task-enable-contact-search'],
    )
    const interactionsCombined = interactions.concat(tasksEnabledForSearch as IInteraction[])

    // Order interactions from newest to oldest
    return orderBy(interactionsCombined, 'connectedToAgentTimestamp', 'desc')
}

export const transformContacts = (contacts: ContactState[]): IInteraction[] => {
    // Function to perform any needed transformations on the incoming interactions before being added to the contactHistory state
    return contacts.map((contact) => {
        const { disconnectTimestamp, connectedToAgentTimestamp, attributes = {} } = contact
        const interactionDuration =
            disconnectTimestamp && connectedToAgentTimestamp
                ? disconnectTimestamp - connectedToAgentTimestamp
                : 0
        const channelType = getChannelType(contact)
        const agentName = contact.agentName ?? contact.attributes['sa-agent-name']
        const initiationMethodDisplay = getInitiationMethodToDisplay(contact)

        return {
            ...contact,
            interactionDuration,
            channelType,
            attributes,
            agentName,
            initiationMethodDisplay,
        } as IInteraction
    })
}

const getInitiationMethodToDisplay = (
    contact: ContactState,
): ContactState['initiationMethodDisplay'] => {
    if (contact.initiationMethod === undefined) return contact.initiationMethod

    const suffix = contact.attributes?.['sa-barged'] === 'yes' ? ' - BARGED' : ''

    return `${contact.initiationMethod}${suffix}`
}

export const transformSMSCampaignETRToInteraction = (etr: IETR): Partial<IInteraction> => {
    let channelType: ChannelType
    let agentName: string

    switch (etr.type) {
        case ETRType.SMS_CAMPAIGN_MESSAGE:
            channelType = channelTypes['SMS CAMPAIGN-MESSAGE']
            agentName = etr.attributes['sa-agent-name'] || 'CAMPAIGN'
            break
        case ETRType.SMS_MARKETING_MESSAGE:
            channelType = channelTypes['SMS MARKETING-MESSAGE']
            agentName = 'MARKETING'
            break
    }

    const transcript: (InteractionMessage | Partial<InteractionMessage>)[] | undefined = etr.content
        ? [
              {
                  Id: `${etr.ID}-message`,
                  content: etr.content,
                  AbsoluteTime: new Date(etr.timestamp).toISOString(),
                  Type: SocialChatJsonMessageType.MESSAGE,
                  DisplayName: 'SYSTEM_MESSAGE',
                  ContentType: 'text/plain',
                  ParticipantRole: 'SYSTEM',
              },
          ]
        : undefined

    return {
        ID: etr.ID,
        channelType,
        initiationTimestamp: etr.timestamp,
        channel: 'CHAT',
        attributes: etr.attributes,
        queueName: 'N/A',
        agentName,
        connectedToSystemTimestamp: etr.timestamp,
        disconnectReason: 'SYSTEM DISCONNECT',
        initiationMethod: 'OUTBOUND',
        customerEndpointAddress: etr.attributes['sa-customer-endpoint-address'],
        systemEndpointAddress: etr.attributes['sa-system-endpoint-address'],
        attributesUpdated: true,
        transcript,
        initiationMethodDisplay: 'OUTBOUND',
    }
}

export const constructEmailMessage = (
    subject: string | undefined,
    systemEndpointAddress: string | undefined,
    customerEndpointAddress: string | undefined,
    message: ThreadMessage,
): IEmailMessage => {
    const {
        messageID,
        direction,
        ccEmailAddress,
        actionAuditID,
        timestamp,
        content,
        type,
        contactID,
        attachments = [],
        externalFrom,
        forwardedTo,
        repliedTo,
        ccEndpointAddress,
    } = message

    const systemDisplayName = message.agent?.name || systemEndpointAddress

    const displayedCc = ccEmailAddress || 'None found'
    const displayedSubject = subject || 'None found'
    const displayedFrom =
        (direction === 'OUTBOUND'
            ? systemDisplayName
            : ccEndpointAddress ?? customerEndpointAddress) ?? 'None found'
    const displayedTo =
        (direction === 'OUTBOUND' ? customerEndpointAddress : systemDisplayName) ?? 'None found'

    return {
        direction,
        actionAuditId: actionAuditID,
        contactId: contactID,
        messageId: messageID,
        timestamp,
        from: displayedFrom,
        to: displayedTo,
        subject: displayedSubject,
        content,
        type: ccEndpointAddress ? 'CCREPLY' : externalFrom ? 'EXTERNAL' : type,
        cc: displayedCc,
        attachments,
        externalFrom,
        forwardedTo,
        repliedTo,
    } as IEmailMessage
}

export const attachmentsRequireTransformation = (attachments: IEmailAttachment[]): boolean => {
    return attachments.length > 0 && !('s3URL' in attachments[0])
}

export const mergeAndMapData = (
    ctrData: ContactState[] | undefined,
    etrData: IETR[] | undefined,
): ContactHistory | undefined => {
    if (!etrData && !ctrData) return
    if (etrData) {
        const mappedETRData = etrData.map(transformSMSCampaignETRToInteraction)
        if (ctrData) {
            const mappedCTRData = transformContacts(ctrData)
            return [...mappedCTRData, ...mappedETRData].sort(
                (a, b) => b.initiationTimestamp! - a.initiationTimestamp!,
            ) as ContactHistory
        }
        return mappedETRData
    }
    if (ctrData) {
        const ctrs = transformContacts(ctrData)
        return ctrs
    }
}

export const getInteractionPropertiesFromMessage = (
    message: IEmailMessage,
    contactAgentName: string | undefined,
) => {
    const { actionAuditId, timestamp, messageId, direction, agentName, type, cc } = message

    const channelType: ChannelType =
        direction === 'OUTBOUND'
            ? 'TASK OUTBOUND'
            : type === 'CCREPLY'
              ? 'TASK CCREPLY'
              : 'TASK INBOUND'

    const ccEmailList = cc === 'None found' || !cc ? '' : cc

    return {
        agentName: contactAgentName ?? agentName,
        actionAuditId,
        messageId: messageId,
        connectedToAgentTimestamp: timestamp,
        channelType,
        ccEmailList,
    }
}

export function convertInteractionToResult(interaction: IInteraction): ContactSearchResult {
    const isDM =
        interaction.attributes['sa-sub-channel'] === 'SMS-DM' ||
        interaction.attributes['sa-sub-channel'] === 'WHATSAPP-DM'

    const customerEndpointAddress =
        interaction.attributes['sa-customer-endpoint-address'] ||
        interaction.customerEndpointAddress

    const phoneNumber = interaction.attributes['sa-dialled-number'] ?? customerEndpointAddress

    const email: string | undefined =
        interaction.attributes['sa-customer-email'] || customerEndpointAddress

    const emailMessage = interaction.emailMessages?.find(
        (message) => message.messageId === interaction.messageId,
    )

    const dataRetention =
        interaction.attributes?.['sa-do-not-expire'] === 'true' ? (
            <div className="data-retention-extended-alert">
                <AlertIcon fill="green" className="sa-editor-warning-icon" /> Extended
            </div>
        ) : (
            'Default'
        )

    function getTelephoneNumber(interaction: IInteraction): string {
        if (interaction.channel === Channel.TASK) {
            return ''
        }
        return isDM
            ? interaction.attributes['sa-customer-endpoint-address']
            : formatPhoneNumber(phoneNumber)
    }

    function getAttachmentCount(interaction: IInteraction): number | undefined {
        const count = getVideoMeetingAttachmentCount(interaction)
        if (count) return count

        return Number(interaction.attributes['sa-message-attachment-count'] ?? 0)
    }

    function getVideoMeetingAttachmentCount(interaction: IInteraction): number | undefined {
        if (interaction.channel === Channel.VOICE && interaction.attributes['sa-video-meeting-id'])
            return Number(interaction.attributes['sa-video-meeting-attachment-count'] ?? 0) + 1
    }

    return {
        ...interaction,
        agentName:
            interaction.agentName ??
            interaction.attributes['sa-agent-name'] ??
            'No agent name found',
        duration: getContactDurationSeconds(interaction),
        telephoneNumber: getTelephoneNumber(interaction),
        socialHandle: interaction.channel === Channel.CHAT ? customerEndpointAddress : '',
        email: email?.includes('@') ? email : '',
        subject: emailMessage?.subject ?? interaction.attributes['sa-message-subject'] ?? '',
        dataRetention,
        attachmentCount: getAttachmentCount(interaction),
        videoMeetingAttachmentCount: getVideoMeetingAttachmentCount(interaction) ?? 0,
    }
}
